import React, { useState } from 'react';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import PleaseLogIn from '@components/Modal/PleaseLogIn';
import useIsRestricted from '@hooks/useIsRestricted';
import { toggleContrast } from '@components/Accessibility/AccessibilityContainer';
import { Button } from 'antd';
import {
  HomeFilled,
  CalendarFilled,
  QuestionCircleFilled,
  InfoCircleFilled,
  CarryOutFilled,
  BgColorsOutlined,
  ContainerFilled,
  PlusCircleFilled
} from 'antd/icons';
import Link from 'next/link';
import { InfoModal } from 'vl-common/src/components/Modals';
import UIVersionLabel from 'vl-common/src/components/UIVersionLabel';
import { MoreMenu } from './MoreMenu';

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  gap: 0.5em;
  z-index: 1000;
  background-color: var(--productPrimary);
  box-shadow: 0 -6px 6px rgba(0, 0, 0, 0.12);
  padding: 0 1em;

  position: fixed;
  bottom: 0;
  width: 100%;

  @media (max-width: 532px) {
    padding: 0 0.5em;
  }
`;

const StyledAboutDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;

  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 12px;
  }

  .dev-tools {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 8px;

    .dev-tools-button {
      height: 60px;
    }
  }

  .har-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;

    .har-button {
      width: 48%;
      height: 60px;
    }
  }
`;

const StyledButton = styled.button<{ active: boolean; badge: string | null }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) => (active ? '#ffffff' : 'transparent')};
  color: ${({ active }) => (active ? '#000000' : '#ffffff')};
  width: 20%;
  border: 0;
  padding: 18px 0 14px 0;
  cursor: pointer;

  .nav-icon {
    font-size: 24px;
    color: ${({ active }) => (active ? 'var(--productPrimary)' : '#ffffff')};
    margin-bottom: 4px;
  }

  ${({ badge }) =>
    badge !== null
      ? `.nav-icon::after {
        content: '${badge}';
        border-radius: 16px;
        background-color: red;
        display: inline-block;
        height: 15px;
        font-size: 12px;
        position: absolute;
        padding: 0 6px;
        top: 10px;
        margin-left: 16px;
        line-height: 16px;
        color: #ffffff;
        font-weight: bold;
      }`
      : ''}
  .nav-text {
    color: ${({ active }) => (active ? 'var(--productPrimary)' : '#ffffff')};
  }

  @media (max-width: 672px) {
    font-size: 12px;
  }
  @media (max-width: 532px) {
    font-size: 10px;
  }
`;

type MenuButtonProps = {
  name: string;
  Icon: any;
  action: () => void;
  testid: string;
  active: boolean;
  badge?: string | null;
  isRestricted: boolean;
  openSignIn: () => void;
  restricted?: boolean;
};

const MenuButton = ({
  name,
  Icon,
  action,
  testid,
  active,
  badge = null,
  isRestricted,
  openSignIn,
  restricted = false
}: MenuButtonProps) => {
  const onClickHandler = () => {
    if (restricted && isRestricted) {
      openSignIn();
    } else {
      action();
    }
  };
  return (
    <StyledButton data-testid={testid} onClick={onClickHandler} active={active} badge={badge}>
      <Icon aria-hidden className="nav-icon" />
      <span className="nav-text">{name}</span>
    </StyledButton>
  );
};

type NavigationFooterProps = {
  logRocketSessionURL: string;
  isProd: boolean;
  theme: any;
  cycleThemes: () => void;
  DATABASE_VERSION: string;
  inRecordingMode: boolean;
  setInRecordingMode: (value: boolean) => void;
  inTestingMode: boolean;
  setTestingModalOpen: (value: boolean) => void;
  setDumpModalOpen: (value: boolean) => void;
  clear: () => void;
  record: () => Promise<void>;
  dump: () => void;
};

export const NavigationFooter = ({
  logRocketSessionURL,
  isProd,
  theme,
  cycleThemes,
  DATABASE_VERSION,
  inRecordingMode,
  setInRecordingMode,
  inTestingMode,
  setTestingModalOpen,
  setDumpModalOpen,
  clear,
  record,
  dump
}: NavigationFooterProps) => {
  const router = useRouter();
  const { pathname } = router;
  const isRestricted = useIsRestricted();
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const menuItems = [
    {
      name: 'Home',
      action: () => router.push('/patients'),
      active: pathname === '/patients',
      testid: 'patients-dashboard-link',
      Icon: HomeFilled
    },
    {
      name: 'To-do list',
      action: () => router.push('/patients/todos'),
      active: pathname === '/patients/todos',
      testid: 'patients-todos-link',
      Icon: CarryOutFilled
    },
    {
      name: 'Appointments',
      action: () => router.push('/patients/appointments'),
      active:
        pathname === '/patients/appointments' ||
        pathname.includes('/patients/timeline') ||
        pathname.includes('/patients/session') ||
        pathname.includes('/patients/choose'),
      restricted: true,
      testid: 'patients-appointments-link',
      Icon: CalendarFilled
    },
    {
      name: 'Documents',
      action: () => router.push('/patients/documents'),
      active: pathname === '/patients/documents',
      testid: 'patients-documents-link',
      Icon: ContainerFilled
    },
    {
      name: 'More',
      action: () => setMoreMenuOpen(!moreMenuOpen),
      active: moreMenuOpen || pathname === '/patients/support' || aboutModalOpen,
      testid: 'patients-more-link',
      Icon: PlusCircleFilled
    }
  ];

  const moreMenuItems = [
    {
      name: 'Support',
      action: () => router.push('/patients/support'),
      active: pathname === '/patients/support',
      testid: 'patients-support-link',
      Icon: QuestionCircleFilled
    },
    {
      name: 'About',
      action: () => {
        setAboutModalOpen(true);
      },
      testid: 'patients-about-link',
      active: aboutModalOpen,
      Icon: InfoCircleFilled
    },
    {
      name: 'Contrast',
      action: () => {
        toggleContrast();
      },
      active: false,
      testid: 'patients-contrast-link',
      Icon: BgColorsOutlined
    }
  ];

  return (
    <StyledFooter>
      {menuItems.map((item) => {
        return (
          <MenuButton
            key={item.name}
            {...item}
            data-testid={item.testid}
            openSignIn={() => setSignInModalOpen(true)}
            isRestricted={isRestricted}
          />
        );
      })}
      <PleaseLogIn open={signInModalOpen} onCancel={() => setSignInModalOpen(false)} />
      <MoreMenu open={moreMenuOpen} options={moreMenuItems} onClose={() => setMoreMenuOpen(false)} />

      <InfoModal
        title="About"
        open={aboutModalOpen}
        onCancel={() => setAboutModalOpen(false)}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
      >
        <StyledAboutDiv>
          <div className="footer-content">
            <UIVersionLabel />
          </div>

          <div className="footer-content">
            <a
              rel="noreferrer"
              target="_blank"
              href="/patients/legal/?all_policies=0&activeTab=3"
              tabIndex={0}
              data-testid="terms"
            >
              Terms
            </a>
            <Link href="/accessibility" data-testid="accessibility">
              Accessibility
            </Link>
            <a href="/regulatory">
              <img src="/static/images/ukca.png" alt="UKCA Mark" width="25px" />
            </a>
            {logRocketSessionURL && (
              <a href={logRocketSessionURL}>
                <img src="/static/images/logRocket.png" width="30px" alt="LogRocket" />
              </a>
            )}
          </div>

          {!isProd && (
            <div className="dev-tools">
              <span>Dev Tools:</span>
              <Button data-testid="dev-change-theme" onClick={cycleThemes} className="dev-tools-button">
                Theme({theme.id})
              </Button>
              <div data-testid="db-version">DB Version: {DATABASE_VERSION}</div>
              <div className="har-buttons">
                <Button
                  data-testid="clear-har-button"
                  className="har-button"
                  onClick={() => {
                    clear();
                  }}
                  disabled={inRecordingMode || inTestingMode}
                >
                  Clear
                </Button>
                <Button
                  onClick={() => {
                    if (!inRecordingMode) {
                      record().then(() => {
                        setInRecordingMode(true);
                        sessionStorage.setItem('recording', 'true');
                      });
                    }
                  }}
                  className="har-button"
                  data-testid="record-button"
                  disabled={inTestingMode}
                  style={inRecordingMode ? { background: 'green', color: 'white' } : {}}
                >
                  {!inRecordingMode ? 'Record' : 'Recording...'}
                </Button>
                <Button
                  data-testid="dump-har-button"
                  onClick={() => {
                    dump();
                    setInRecordingMode(false);
                    sessionStorage.removeItem('recording');
                    setAboutModalOpen(false);
                    setDumpModalOpen(true);
                  }}
                  className="har-button"
                >
                  Dump
                </Button>
                <Button
                  onClick={() => {
                    setAboutModalOpen(false);
                    setTestingModalOpen(true);
                  }}
                  data-testid="testing-mode-button"
                  style={inTestingMode ? { background: 'green', color: 'white' } : {}}
                  disabled={inRecordingMode}
                  className="har-button"
                >
                  Testing mode
                </Button>
              </div>
            </div>
          )}
        </StyledAboutDiv>
      </InfoModal>
    </StyledFooter>
  );
};
