// import { URLSearchParams } from 'url';
import { z } from 'zod';
import {
  ResponseSchema as AppointmentMetaResponseSchema,
  ApptActionCodes,
  ApptActionCodesSchema
} from 'vl-common/src/schemas/getMetaAppointments';
import * as actionTypes from './actionTypes';
import { fetchData, VLThunkAction } from './action';

/* imgDetailsMetaRes */
export const imgDetailsMetaRes = (clinician_user_guid, appointment_id) => {
  const requestOption = {
    params: `meta/invreq/${clinician_user_guid}/${appointment_id}`
  };
  return fetchData(requestOption);
};

export const getPatientDetails = (clinician_user_guid: string, patient_user_guid: string) => {
  const requestOption = {
    params: `referrers/patients/${clinician_user_guid}?pat_guid=${patient_user_guid}`
  };
  return fetchData(requestOption);
};

export const getClinicianDetails = (clinician_user_guid: string, patient_user_guid: string) => {
  const requestOption = {
    params: `users/user/${clinician_user_guid}?pat_guid=${patient_user_guid}`
  };
  return fetchData(requestOption);
};

export const getSavedPDF = (appointment_id, ireq_id?) => {
  const requestOption = {
    params: `service-request?appt_id=${appointment_id}${ireq_id ? `&ireq_id=${ireq_id}` : ''}`,
    actionType: actionTypes.GET_PDF_FORM_SUCCESS,
    errorType: actionTypes.GET_PDF_FORM_FAIL
  };
  return fetchData(requestOption);
};

export const getClinicianSignature = () => {
  const requestOption = {
    params: 'signature'
  };
  return fetchData(requestOption);
};

export const postCurrentPDF = (appointment_id, ireq_id) => {
  const requestOption = {
    params: `service-request?appt_id=${appointment_id}&ireq_id=${ireq_id}`,
    method: 'post'
  };
  return fetchData(requestOption);
};

export const getProviders = (
  clinician_user_guid,
  imagingType,
  postcode,
  appointment_id,
  tag_filter: boolean = false
) => {
  const requestOption = {
    params: `providers/imgprovs/${clinician_user_guid}?page=1&rows=18&type=${imagingType}&geo_postcode=${postcode}&appt_id=${appointment_id}&tag_filter=${tag_filter}`
  };
  return fetchData(requestOption);
};

export const postPDFState = (appointment_id, ireq_id, postBody) => {
  const options = {
    params: `service-request?appt_id=${appointment_id}&ireq_id=${ireq_id}`,
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const sendAudioCallStarted = (appt_id: number, tel_no: string) => {
  const options = {
    params: 'clinicians/appt/calls/audio/APPUSER',
    method: 'post',
    postBody: {
      appt_id,
      tel_no
    }
  };
  return fetchData(options);
};

// Here
export const deleteInvestigationRequest = (appointmentId, investigationRequestId) => {
  const options = {
    params: 'clinicians/delete/invreq',
    method: 'post',
    postBody: {
      appt_id: appointmentId,
      ireq_id: investigationRequestId
    }
  };
  return fetchData(options);
};

interface CreateInvestigationRequestBody {
  user_guid: string;
  appt_id: number;
  urgency_code: string;
  img_type_code: string;
  contrast_scan?: boolean;
  body_region_code?: string;
  body_side?: string;
  reason?: string;
  history?: string;
}

export const createInvestigationRequest = (postBody: CreateInvestigationRequestBody) => {
  const options = {
    params: 'clinicians/invreq',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

interface UpdateInvestigationRequestBody {
  user_guid: string;
  ireq_id: number;
  appt_id: number;
  urgency_code: string;
  img_type_code: string;
  contrast_scan?: boolean;
  body_region_code?: string;
  body_side?: string;
  reason?: string;
  history?: string;
}

export const updateInvestigationRequest = (postBody: UpdateInvestigationRequestBody) => {
  const options = {
    params: 'clinicians/invreq',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const postProviderData = (postBody) => {
  const options = {
    params: 'clinicians/invreq/provider',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const getDrugsByName = (clinician_user_guid: string, appointment_id: number, drug_name: string) => {
  const options = {
    params: `medication/vtm/${clinician_user_guid}?appt_id=${appointment_id}&vtm_name=${drug_name}`
  };
  return fetchData(
    options,
    z.object({
      vtm: z.array(
        z.object({
          vtm_id: z.string(),
          vtm_name: z.string(),
          bnf_url: z.string().nullable()
        })
      )
    })
  );
};

export const getDrugForms = (clinician_user_guid: string, appointment_id: number, vtm_id: string) => {
  const options = {
    params: `medication/vmp/${clinician_user_guid}?appt_id=${appointment_id}&vtm_id=${vtm_id}`
  };
  return fetchData(
    options,
    z.object({
      vmp: z.array(
        z.object({
          vtm_id: z.string(),
          vtm_name: z.string(),
          vmp_id: z.string(),
          vmp_name: z.string(),
          bnf_url: z.string()
        })
      )
    })
  );
};

export const createSavedMedication = (postBody: {
  p_user_guid?: string;
  appt_id: number;
  vmp_id?: string;
  frequency: string;
  duration: string;
  med_id?: string;
}) => {
  const options = {
    params: 'medication/create',
    method: 'post',
    postBody
  };
  return fetchData(
    options,
    z.object({
      med_id: z.number(),
      patient_id: z.string(),
      case_id: z.number(),
      appt_id: z.number(),
      operation: z.string()
    })
  );
};

export const disableSavedMedication = (postBody: { p_user_guid?: string; appt_id: number; med_id: string }) => {
  const options = {
    params: 'medication/disable',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const setAppointmentWillPrescribe = (appt_id: number, prescribe: boolean) => {
  const options = {
    params: `medication/appt/prescribe/APPUSER?appt_id=${appt_id}&prescribe=${prescribe ? 1 : 0}`,
    method: 'post',
    postBody: {}
  };
  return fetchData(options);
};

// bottom

// Satisfaction Response
export const clinicianSatisfactionScore = () => {
  const options = {
    params: 'clinicians/satis/APPUSER',
    actionType: actionTypes.CLINICIANS_SATISFACTION_SCORE_SUCCESS,
    errorType: actionTypes.CLINICIANS_SATISFACTION_SCORE_FAIL
  };
  return fetchData(options);
};

// Timeline appointment history
export const clinicianAppointment = (params, isCompleted) => {
  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  const requestUrl = `booking/appointments/APPUSER?${queryString}&completed=${isCompleted}`;
  const isCompleteTrue = {
    params: requestUrl,
    actionType: actionTypes.CLINICIANS_APPOINTMENT_COMPLETE_SUCCESS,
    errorType: actionTypes.CLINICIANS_APPOINTMENT_COMPLETE_FAIL
  };
  const isCompleteFalse = {
    params: requestUrl,
    actionType: actionTypes.CLINICIANS_APPOINTMENT_ACTIVE_SUCCESS,
    errorType: actionTypes.CLINICIANS_APPOINTMENT_ACTIVE_FAIL
  };
  const parameters = isCompleted ? isCompleteTrue : isCompleteFalse;
  return fetchData(parameters);
};

export const getGPRecords = (appt_id, page) => {
  const options = {
    params: `gpc?appt_id=${appt_id}`,
    method: 'post',
    postBody: {
      appt_id,
      page
    }
  };
  return fetchData(options);
};

export const getErsReferralAttachments = (appt_id, ubrn) => {
  const options = {
    params: `ers/referral/attachments?user_guid=APPUSER&appt_id=${appt_id}&ubrn=${ubrn}`,
    method: 'GET'
  };
  return fetchData(options);
};

export const getErsReferralDocument = (appt_id, ubrn, attachment_id) => {
  const options = {
    params: `ers/referral/document?user_guid=APPUSER&appt_id=${appt_id}&ubrn=${ubrn}&attachment_id=${attachment_id}`,
    method: 'GET'
  };
  return fetchData(options);
};

export const getErsReferralInformation = (appt_id, ubrn) => {
  // seen user_guid replaced by APPUSER elsewhere

  const options = {
    params: `ers/referral/information?user_guid=APPUSER&appt_id=${appt_id}&ubrn=${ubrn}`,
    method: 'GET'
  };
  return fetchData(options);
};

export const getErsPatientDetails = (appt_id, ubrn) => {
  const options = {
    params: `ers/referral/patient?user_guid=APPUSER&appt_id=${appt_id}&ubrn=${ubrn}`,
    method: 'GET'
  };
  return fetchData(options);
};

export const getUBRNsFromCaseNotes = (case_id, patient_guid, appt_id) => {
  const options = {
    params: `notes/patient/APPUSER?case_id=${case_id}&patient_guid=${patient_guid}&appt_id=${appt_id}&category=ERS&rows=999`,
    method: 'GET'
  };
  return fetchData(options);
};

export const getCaseNotes = (case_id, patient_guid, appt_id) => {
  const options = {
    params: `notes/patient/APPUSER?case_id=${case_id}&patient_guid=${patient_guid}&appt_id=${appt_id}&rows=999`,
    method: 'GET'
  };
  return fetchData(options);
};

const AppointmentDetailsSchema = z.object({
  appt_id: z.number(),
  appt_version: z.union([z.literal(1), z.literal(2)]),
  appointment_type: z.string(),
  clinician_name: z.string(),
  clinician_guid: z.string(),
  clinician_subtype_code: z.string(),
  clinician_subtype_desc: z.string(),
  appt_type_code: z.string(),
  appt_type_desc: z.string(),
  appt_date: z.string().nullable(),
  appt_completed_date: z.string().nullable(),
  appt_outcome_code: z.string().nullable(),
  appt_outcome_desc: z.string().nullable(),
  icd_code: z.string().nullable(),
  icd_desc: z.string().nullable(),
  formal_diagnosis: z.string().nullable(),
  notes: z.array(
    z.object({
      note_code: z.string(),
      note_desc: z.string(),
      note_text: z.string().nullable()
    })
  ),
  factsheets: z.array(
    z.object({
      factsheet_id: z.number(),
      factsheet_name: z.string(),
      factsheet_url: z.string()
    })
  ),
  genadv: z.array(
    z.object({
      genadv_code: z.string(),
      genadv_desc: z.string()
    })
  ),
  inv: z.array(
    z.object({
      service_type_code: z.string(),
      service_type_desc: z.string()
    })
  ),
  invreq: z.array(
    z.object({
      ireq_id: z.number(),
      service_type_code: z.string(),
      service_type_desc: z.string(),
      hospital_id: z.number(),
      hospital_name: z.string(),
      urg_code: z.string(),
      urg_desc: z.string(),
      state_code: z.string(),
      state_desc: z.string()
    })
  ),
  meds: z.array(
    z.object({
      med_id: z.number(),
      vtm_name: z.string(),
      vtm_id: z.string(),
      vmp_id: z.string(),
      vmp_name: z.string(),
      bnf_url: z.string().nullable(),
      frequency: z.string().nullable(),
      duration: z.string().nullable(),
      meds_prescribed: z.string(),
      prescription_id: z.unknown().nullable()
    })
  ),
  appt_actions: z.array(
    z.object({
      appt_action_code: ApptActionCodesSchema,
      auth_granted: z.number().nullable(),
      action_subtype_code: z.string().nullable(),
      action_spec_cat_code: z.string().nullable(),
      action_spec_area_code: z.string().nullable(),
      auth_start_date: z.string().nullable(),
      auth_end_date: z.string().nullable(),
      discharge_code: z.string().nullable(),
      refer_self: z.number().nullable(),
      action_task_comment: z.string().nullable()
    })
  )
});
export type AppointmentDetails = z.infer<typeof AppointmentDetailsSchema>;

export const getAppointmentDetails = (current_appt_id: number) => {
  const options = {
    params: `clinicians/appt/details/APPUSER?appt_id=${current_appt_id}`,
    method: 'GET'
  };
  return fetchData(
    options,
    z.object({
      appts: z.array(AppointmentDetailsSchema)
    })
  );
};

export const getSharedReferrerNotes = (patient_guid, case_id = '') => {
  const options = {
    params: `notes/patient/APPUSER?patient_guid=${patient_guid}&rows=999${case_id ? `&case_id=${case_id}` : ''}`,
    method: 'GET'
  };
  return fetchData(options);
};

export const clinicalValidation = (postBody) => {
  const options = {
    params: 'surveys/covid19',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const submitCaseReview = (postBody) => {
  const options = {
    params: 'booking/case/review',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const clinicianMeta = () => {
  const options = {
    params: 'meta/clinicians/APPUSER'
  };
  return fetchData(options);
};

export const specialityAreas = (speccat) => {
  const options = {
    params: `admin/specareas/APPUSER?rows=999&sort=area&speccat=${speccat}`
  };
  return fetchData(options);
};

export const clinicianViewCaseNotes = (id) => {
  const options = {
    params: `notes/patient/APPUSER/?case_id=${id}&sort=date_added&order=desc`
  };
  return fetchData(options);
};

export const clinicianAppointmentSuggestions = (postBody: {
  user_guid: string;
  appt_id: number;
  genadv: { genadv_code: string }[];
  inv: { service_type_code: string }[];
}) => {
  const options = {
    params: 'clinicians/appt/suggest',
    method: 'POST',
    postBody
  };
  return fetchData(options, z.object({ appt_id: z.number() }));
};

export const clinicianAddCaseNotes = (postBody) => {
  const options = {
    params: 'notes/patient',
    method: 'POST',
    postBody
  };
  return fetchData(options);
};

export const clinicianNotesPost = (postBody) => {
  const options = {
    params: 'notes/appt/',
    postBody,
    method: 'post'
  };
  return fetchData(options);
};

export const clinicianFlagsPost = (postBody) => {
  const options = {
    params: 'cases/flags',
    postBody,
    method: 'POST'
  };
  return fetchData(options);
};

export const clinicianInvReqByApptId = (page, appt_id) => {
  const options = {
    params: `clinicians/invreq/APPUSER?page=${page}&appt_id=${appt_id}`
  };
  return fetchData(options);
};
export const clinicianInvReqByCaseId = (page, case_id) => {
  const options = {
    params: `clinicians/invreq/APPUSER?page=${page}&case_id=${case_id}`
  };
  return fetchData(options);
};

export const clinicianInvReqImgDetails = () => {
  const options = {
    params: 'meta/invreq/APPUSER'
  };
  return fetchData(options);
};

export const clinicianInvReqSurvey = (survey_id, appt_id) => {
  const options = {
    params: `clinicians/invreq/survey/APPUSER?hier_code=INVREQ${survey_id}&appt_id=${appt_id}`
  };
  return fetchData(options);
};

export const getClinician = (clin_guid: string) => {
  const options = {
    params: `clinicians/clinicians/${clin_guid}?clin_guid=${clin_guid}`
  };
  return fetchData(options);
};

export const clinicianInvReqSurveyPost = (user_guid, appt_id, postBody) => {
  const options = {
    params: 'clinicians/invreq/survey',
    postBody: {
      ...postBody,
      user_guid,
      appt_id
    },
    method: 'post'
  };
  return fetchData(options);
};

export const clinicianInvReqProvider = (postcode, type) => {
  const options = {
    params: `providers/imgprovs/APPUSER?rows=99&geo_postcode=${postcode}&type=${type}`
  };
  return fetchData(options);
};

export const clinicianInvReqProviderPost = (user_guid, appt_id, postBody) => {
  const options = {
    params: 'clinicians/invreq/provider',
    postBody: {
      ...postBody,
      user_guid,
      appt_id
    },
    method: 'POST'
  };
  return fetchData(options);
};

export const clinicianDiagnosisAndOutcomeAppointmentOutcomeSave = (request: {
  user_guid: string;
  appt_id: number;
  /** @deprecated */
  rtt?: number;
  /** @deprecated */
  priority?: number;
  outcome_code?: string;
  appt_actions?: {
    appt_action_code: ApptActionCodes;
    action_subtype_code?: string;
    auth_start_date?: Date;
    auth_end_date?: Date;
  }[];
}) => {
  const options = {
    postBody: request,
    method: 'POST',
    params: 'clinicians/appt/outcome',
    actionType: actionTypes.CLINICIANS_SAVE_APPOINTMENT_OUTCOME_SUCCESS,
    errorType: actionTypes.CLINICIANS_SAVE_APPOINTMENT_OUTCOME_FAIL
  };
  return fetchData(options);
};

export const clinicianICD10DiagnosisSearch = (search: string) => {
  const options = {
    noUrl: false,
    params: `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&authenticity_token=&terms=${search}&count=15`
  };
  return fetchData(
    options,
    z.tuple([z.number(), z.array(z.string()), z.null(), z.array(z.tuple([z.string(), z.string()]))])
  );
};

export const clinicianICD10DiagnosisSave = (request: {
  user_guid: string;
  appt_id: number;
  icd_code: string;
  icd_description: string;
  icd_version: string;
}) => {
  const options = {
    postBody: request,
    method: 'POST',
    params: 'clinicians/appt/icd'
  };
  return fetchData(options);
};

export const clinicianICD10DiagnosisDelete = (appointment_id: number, clinician_user_guid: string) => {
  const options = {
    postBody: { user_guid: clinician_user_guid, appt_id: appointment_id },
    method: 'POST',
    params: 'clinicians/appt/delete/icd'
  };
  return fetchData(options);
};

export const clinicianDiagnosisTypeSave = (request: {
  appt_id: number;
  user_guid: string;
  formal_diagnosis: boolean;
}) => {
  const options = {
    postBody: request,
    method: 'POST',
    params: 'clinicians/appt/diag'
  };
  return fetchData(options);
};

export const clinicianDiagnosisAndOutcomePifuGet = (user_guid, appt_id) => {
  const options = {
    method: 'GET',
    params: `clinicians/appt/pifu/${user_guid}/?appt_id=${appt_id}`
  };
  return fetchData(options);
};

export const clinicianDiagnosisAndOutcomePifuSave = (request) => {
  const options = {
    postBody: request,
    method: 'POST',
    params: 'clinicians/appt/pifu'
  };
  return fetchData(options);
};

export const clinicianDOFactsheets = (search: string, appt_id: number) => {
  const options = {
    params: `users/factsheets/APPUSER?rows=999&name=${search !== undefined ? search : ''}&appt_id=${appt_id}`
  };
  return fetchData(
    options,
    z.object({
      factsheets: z.array(
        z.object({
          sheet_id: z.number(),
          sheet_name: z.string(),
          filename: z.string(),
          url: z.string()
        })
      )
    })
  );
};

export const clinicianGeneralGet = (appt_id: number) => {
  const options = {
    params: `meta/suggest/APPUSER/${appt_id}`
  };
  return fetchData(
    options,
    z.object({
      genadv: z.record(z.string(), z.array(z.object({ genadv_code: z.string(), genadv_desc: z.string() }))).nullable(),
      inv: z
        .record(z.string(), z.array(z.object({ service_type_code: z.string(), service_type_desc: z.string() })))
        .nullable()
    })
  );
};
export const clinicianDOFactsheetSave = (request) => {
  const options = {
    method: 'POST',
    postBody: request,
    params: 'clinicians/appt/factsheet'
  };
  return fetchData(options);
};

export const appointmentTasks = (appt_id: number, restrict_mode?: string) => {
  const requestOption = {
    params: `tasks/APPUSER?appt_id=${appt_id}${restrict_mode ? `&restrict_mode=${restrict_mode}` : ''}`
  };

  return fetchData(
    requestOption,
    z.object({
      Tasks: z
        .array(
          z.object({
            task_id: z.number(),
            task_type: z.string(),
            task_type_code: z.string(),
            task_notes: z.string(),
            created_date: z.coerce.date(),
            due_date: z.coerce.date(),
            task_subtype: z.string().nullable(),
            case_selection: z.string(),
            patient_name: z.string(),
            patient_title: z.string(),
            assigned_by: z.string(),
            assigned_to: z.string(),
            company: z.string(),
            assigned_to_client_id: z.number(),
            reason_for_cancel: z.string().nullable(),
            status: z.string(),
            client_case_id: z.string(),
            appt_id: z.number(),
            closed_date: z.coerce.date().nullable(),
            case_id: z.number(),
            patient_guid: z.string(),
            membership_no: z.string(),
            cat_desc: z.string(),
            cat_code: z.string(),
            area_desc: z.string().nullable(),
            actionable: z.number(),
            creator_name: z.string(),
            bkappt_clin_subtype: z.string(),
            proxy_id: z.unknown().nullable(),
            task_reopenable: z.number()
          })
        )
        .nullable()
    })
  );
};

/* clinician completed  tasks */
export const clinicianAllTasks = (params, isActive) => {
  const parameter =
    params !== undefined
      ? Object.keys(params)
          .map((key) => (params[key] !== undefined ? `${key}=${params[key]}` : ''))
          .join('&')
      : null;

  const isActiveTrue = {
    actionType: actionTypes.CLINICIANS_OPEN_TASKS_SUCCESS,
    errorType: actionTypes.CLINICIANS_OPEN_TASKS_FAIL
  };
  const isActiveFalse = {
    actionType: actionTypes.CLINICIANS_COMPLETED_TASKS_SUCCESS,
    errorType: actionTypes.CLINICIANS_COMPLETED_TASKS_FAIL
  };

  const requestOption = {
    params: `tasks/APPUSER?sort=due_date&order=asc&active=${isActive}${parameter ? `&${parameter}` : ''}`,
    ...(isActive ? isActiveTrue : isActiveFalse)
  };
  return fetchData(requestOption);
};

/* getPreAssetments */
export const clinicianGetPreAssetments = () => {
  const requestOption = {
    params: 'users/preass/APPUSER?hier_code=PREASS1'
  };
  return fetchData(requestOption);
};

/* getPreAssetments Question */
export const clinicianPreAssetmentsQuestion = (request) => {
  const requestOption = {
    params: 'users/preass',
    method: 'POST',
    postBody: request,
    actionType: actionTypes.CLINICIANS_GENERAL_QUESTIONS_SUCCESS,
    errorType: actionTypes.CLINICIANS_GENERAL_QUESTIONS_FAIL
  };
  return fetchData(requestOption);
};

export const uploadDocsDatabase = (request) => {
  const options = {
    params: 'files/appt/files/upload',
    method: 'POST',
    postBody: request,
    actionType: actionTypes.UPLOAD_DOCS_DATABASE_SUCCESS,
    errorType: actionTypes.UPLOAD_DOCS_DATABASE_FAIL
  };
  return fetchData(options);
};

export const uploadCaseDocsDatabase = (request) => {
  const options = {
    params: 'files/case/files/upload',
    method: 'POST',
    postBody: request,
    actionType: actionTypes.UPLOAD_DOCS_DATABASE_SUCCESS,
    errorType: actionTypes.UPLOAD_DOCS_DATABASE_FAIL
  };
  return fetchData(options);
};

export const uploadDocs = (request) => {
  const options = {
    params: 'file',
    method: 'POST',
    postBody: request,
    actionType: actionTypes.UPLOAD_DOCS_SUCCESS,
    errorType: actionTypes.UPLOAD_DOCS_FAIL
  };
  return fetchData(options);
};

export const publishToPatient = (request: {
  /** The calling user’s GUID */
  user_guid: string;
  /** `document_id` from GET `clinicians/appt/letter_files` for a file with `document_type` `Uploaded` */
  file_id: number;
  appt_id?: number;
}) => {
  const options = {
    params: 'files/appt/files/publish_to_patient',
    method: 'POST',
    postBody: request
  };
  return fetchData(options);
};

export const getDocs = (request) => {
  const parameter = Object.keys(request)
    .map((key) => `${key}=${request[key]}`)
    .join('&');
  const options = {
    params: `file?${parameter}`
  };
  return fetchData(options);
};

export const getLetterCodes = (appt_id: number) => {
  const options = {
    params: `clinicians/LetterCodes/APPUSER?appt_id=${appt_id}`
  };
  return fetchData(
    options,
    z.object({
      letter_types: z.array(z.object({ letter_code: z.string(), description: z.string() }))
    })
  );
};

/* Data Protection */
export const dataProtection = (appId) => {
  const options = {
    params: `clinicians/appt/sec/APPUSER?appt_id=${appId}&date_check=false`,
    actionType: actionTypes.DATA_PROTECTION_SUCCESS,
    errorType: actionTypes.DATA_PROTECTION_FAIL
  };
  return fetchData(options);
};

export const getRehab = (user_guid, case_id) => {
  const options = {
    params: `clinicians/case/rehab/APPUSER?user_guid=${user_guid}&case_id=${case_id}`
  };
  return fetchData(options);
};

export const postRehab = (user_guid, case_id, rehab_link) => {
  const options = {
    params: 'clinicians/case/rehab',
    method: 'post',
    postBody: {
      user_guid,
      case_id,
      rehab_link
    }
  };
  return fetchData(options);
};

export const postDataProtection = (request) => {
  const requestOption = {
    params: 'clinicians/appt/sec',
    method: 'POST',
    postBody: request,
    actionType: actionTypes.POST_DATA_PROTECTION_SUCCESS,
    errorType: actionTypes.POST_DATA_PROTECTION_FAIL
  };
  return fetchData(requestOption);
};

export const getLamdaPDF = (userId, appId) => {
  const requestOption = {
    params: `pdfgen?appointmentID=${appId}&userGUID=${userId}`,
    actionType: actionTypes.LAMDA_PDF_SUCCESS,
    errorType: actionTypes.LAMDA_PDF_FAIL
  };
  return fetchData(requestOption);
};

export const getVoiceCallToken = () => {
  const requestOption = {
    params: 'voicetokengen',
    actionType: actionTypes.VOICE_TOKEN_SUCCESS,
    errorType: actionTypes.VOICE_TOKEN_FAIL
  };
  return fetchData(requestOption);
};

export const saveLamdaPDF = (userId, appId, patientGUID, request) => {
  const requestOption = {
    method: 'POST',
    params: `pdfgen?appointmentID=${appId}&userGUID=${userId}&patientUserGUID=${patientGUID}`,
    postBody: request
  };
  return fetchData(requestOption);
};

export const getPatientAssessments = (request) => {
  const queryString = Object.keys(request)
    .map((key) => `${key}=${request[key]}`)
    .join('&');
  const requestOption = {
    params: `surveys/surveyjs/APPUSER?${queryString}`,
    actionType: actionTypes.PATIENT_ASSESSMENTS_SUCCESS,
    errorType: actionTypes.PATIENT_ASSESSMENTS_FAIL
  };
  return fetchData(requestOption);
};

export const getLetterFiles = (request) => {
  const queryString = Object.keys(request)
    .map((key) => `${key}=${request[key]}`)
    .join('&');
  const requestOption = {
    params: `clinicians/appt/letter_files/APPUSER?${queryString}`,
    actionType: actionTypes.LETTER_FILES_SUCCESS,
    errorType: actionTypes.LETTER_FILES_FAIL
  };
  return fetchData(requestOption);
};

export const deleteLetterFile = (request) => {
  const options = {
    params: 'files/appt/files/remove',
    method: 'POST',
    postBody: request
  };
  return fetchData(options);
};

export const taskAssigneeList = () => {
  const options = {
    params: 'tasks/assignee/APPUSER'
  };
  return fetchData(options);
};

export const taskMetaData = () => {
  const options = {
    params: 'meta/tasks/APPUSER'
  };
  return fetchData(options);
};

export const task2MetaData = () => {
  const options = {
    params: 'meta/tasks2/APPUSER'
  };
  return fetchData(options);
};

export const taskPatientLookup = (search) => {
  const options = {
    params: `referrers/patients/APPUSER?page=1&rows=100&order=desc&fuzzy=${search}`
  };
  return fetchData(options);
};

export const taskCases = (patient, isActive) => {
  const options = {
    params: `cases/APPUSER?page=1&rows=10&active=${isActive}&patient=${patient}`,
    actionType: actionTypes.CREATE_TASK_SUCCESS,
    errorType: actionTypes.CREATE_TASK_FAIL
  };
  return fetchData(options);
};

export const taskCreate = (request) => {
  const options = {
    params: 'tasks/create',
    method: 'PUT',
    postBody: request
  };
  return fetchData(options);
};

export const caseTriage = (request) => {
  const options = {
    params: 'cases/triage',
    method: 'POST',
    postBody: request
  };
  return fetchData(options);
};

export const completeSession = (request) => {
  const options = {
    postBody: request,
    method: 'POST',
    params: 'clinicians/appt/complete',
    errorType: actionTypes.ACTION_FAIL
  };
  return fetchData(options);
};

const ReferralClinicianSchema = z.object({
  clin_user_guid: z.string(),
  clin_lucy_id: z.string(),
  consultant_id: z.unknown(),
  title: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
  bio: z.string().nullable(),
  cat_code: z.string(),
  cat_name: z.string(),
  spec_areas: z.string().nullable(),
  spec_area_codes: z.string().nullable(),
  subtype: z.string(),
  subtype_desc: z.string(),
  avatar_url: z.string(),
  signature_url: z.string(),
  gmc: z.string(),
  hcpc: z.unknown(),
  tel_mobile_no: z.string().nullable(),
  tel_other_no: z.string().nullable(),
  client_id: z.number(),
  client_name: z.string(),
  hospital_id: z.number(),
  hospital_name: z.string(),
  hospital_code: z.string(),
  tel_no: z.string(),
  addline1: z.string().nullable(),
  addline2: z.string().nullable(),
  addline3: z.string().nullable(),
  addline4: z.string().nullable(),
  region: z.string(),
  postcode: z.string(),
  country: z.string().nullable(),
  geo_region: z.string().nullable()
});

export type ReferralClinician = z.infer<typeof ReferralClinicianSchema>;

export const getRefClinicians = (spec_cat: string, geo_postcode: string, subSpeciality?: string, caseId?: number) => {
  const options = {
    params: `clinicians/refclins/APPUSER?spec_cat=${spec_cat}&geo_postcode=${geo_postcode}${
      subSpeciality ? `&specarea=${subSpeciality}` : ''
    }${caseId ? `&case_id=${caseId}` : ''}`
  };
  return fetchData(
    options,
    z.object({
      clinicians: z.array(ReferralClinicianSchema)
    })
  );
};

const ReferralSchema = z.object({
  case_id: z.number(),
  appt_id: z.number(),
  client_case_id: z.string(),
  case_spec_cat_code: z.string(),
  case_spec_cat_desc: z.string(),
  case_spec_area_code: z.string().nullable(),
  case_spec_area_desc: z.string().nullable(),
  condition: z.string(),
  case_created_date: z.coerce.date(),
  appointment_date: z.null(),
  patient_user_guid: z.string(),
  patient: z.string(),
  patient_membership_no: z.string(),
  patient_email: z.string(),
  patient_tel_mobile_no: z.string().nullable(),
  patient_tel_other_no: z.string().nullable(),
  patient_postcode: z.string(),
  clinician_user_guid: z.string(),
  clinician: z.string(),
  ref_case_spec_cat_code: z.string(),
  ref_case_spec_cat_desc: z.string(),
  ref_case_spec_area_code: z.string(),
  ref_case_spec_area_desc: z.string(),
  ref_clinician_user_guid: z.string().nullable(),
  ref_clinician: z.string().nullable(),
  hospital_name: z.string().nullable(),
  ref_email: z.string().nullable(),
  ref_case_reason: z.string(),
  ref_reason_code: z.string(),
  ref_postcode: z.string(),
  hospital_id: z.number().nullable(),
  hospital_code: z.string().nullable(),
  hospital_addline1: z.string().nullable(),
  hospital_addline2: z.string().nullable(),
  hospital_addline3: z.string().nullable(),
  hospital_addline4: z.string().nullable(),
  hospital_region: z.string().nullable(),
  hospital_postcode: z.string().nullable(),
  hospital_country: z.string().nullable(),
  urgency_code: z.string(),
  urgency_desc: z.string()
});

export type Referral = z.infer<typeof ReferralSchema>;

export const getReferral = (appt_id: number) => {
  const options = {
    params: `referrers/case/refer/APPUSER/?appt_id=${appt_id}`
  };
  return fetchData(options, z.object({ users: z.array(ReferralSchema).nullable() }));
};
export const getSpecialities = (see_all: 0 | 1 = 0) => {
  const options = {
    params: `admin/speccats/APPUSER/?rows=999&see_all=${see_all}`
  };
  return fetchData(
    options,
    z.object({
      cats: z.array(
        z.object({
          cat_code: z.string(),
          cat_name: z.string(),
          enabled: z.boolean(),
          no_users: z.number(),
          no_areas: z.number(),
          needs_card_details: z.number()
        })
      )
    })
  );
};
export const getSubSpecialities = (spec_cat: string, see_all: 0 | 1 = 0) => {
  const options = {
    params: `admin/specareas/APPUSER/?rows=999&sort=area&speccat=${spec_cat}&see_all=${see_all}`
  };
  return fetchData(
    options,
    z.object({
      areas: z
        .array(
          z.object({
            category_code: z.string(),
            category_name: z.string(),
            area_code: z.string(),
            area_name: z.string(),
            image_url: z.string().nullable(),
            enabled: z.boolean(),
            no_users: z.number(),
            cat_no_users: z.number()
          })
        )
        .nullable()
    })
  );
};

type BasePostReferralBody = {
  appt_id: number;
  hospital_id?: number;
  postcode?: string;
  refclin_guid?: string;
  specarea?: string;
  speccat: string;
  urg_code?: string;
  user_guid: string;
};

export const postReferral = (
  postBody:
    | (BasePostReferralBody & {
        /** A reason for referral code, as defined in `GET` `v1/meta/appointments` */
        ref_reason_code: string;
      })
    | (BasePostReferralBody & {
        /** Free text reason for referral */
        reason: string;
      })
) => {
  const options = {
    params: 'referrers/case/refer',
    method: 'post',
    postBody
  };
  return fetchData(options);
};
export const getAllPDFData = (id) => {
  const options = {
    params: `clinicians/appt/letter/APPUSER?appt_id=${id}`
  };
  return fetchData(options);
};

export const appointmentMetaData = (appt_id: number) => {
  const options = {
    params: `meta/appointments/APPUSER?appt_id=${appt_id}`
  };
  return fetchData(options, AppointmentMetaResponseSchema);
};

export const selectedCase = (patientID) => {
  const options = {
    params: `cases/APPUSER?page=1&rows=100&patient=${patientID}&first_incomplete=true`
  };
  return fetchData(options);
};

export const getFile = (id, file) => {
  const options = {
    params: `file?user_guid=${id}&filename=${file}`
  };
  return fetchData(options);
};

export const getReport = (report_id, dataset_id) => {
  const options = {
    params: `powerbi?report_id=${report_id}&dataset_id=${dataset_id}`
  };
  return fetchData(options);
};

export const getReportGroups = () =>
  fetchData({
    params: 'admin/powerbi/groups/APPUSER'
  });

export const clinicianCases = (params) => {
  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  const requestUrl = `cases/APPUSER?${queryString}&204=true`;
  let parameters = {};
  if (params && params.new_case) {
    parameters = {
      params: requestUrl,
      actionType: actionTypes.CLINICIANS_CASE_NEW_SUCCESS,
      errorType: actionTypes.CLINICIANS_CASE_NEW_FAIL
    };
  } else if (params && params.active) {
    parameters = {
      params: requestUrl,
      actionType: actionTypes.CLINICIANS_CASE_ACTIVE_SUCCESS,
      errorType: actionTypes.CLINICIANS_CASE_ACTIVE_FAIL
    };
  } else {
    parameters = {
      params: requestUrl,
      actionType: actionTypes.CLINICIANS_CASE_CLOSED_SUCCESS,
      errorType: actionTypes.CLINICIANS_CASE_CLOSED_FAIL
    };
  }
  return fetchData(parameters);
};

export const clinicianCasesQueue = (params) => {
  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  const requestUrl = `cases/queues/APPUSER?${queryString}&204=true`;
  let parameters = {};
  parameters = {
    params: requestUrl,
    actionType: actionTypes.CLINICIANS_CASE_NEW_SUCCESS,
    errorType: actionTypes.CLINICIANS_CASE_NEW_FAIL
  };
  return fetchData(parameters);
};

export const getCase = (caseId: number) => {
  const options = {
    params: `cases/APPUSER?case=${caseId}`
  };
  return fetchData(options);
};

export const assignCase = (postBody) => {
  const options = {
    params: 'booking/case/review',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const prescribeSignaturePharmacy = (appt_id) => {
  return fetchData({
    method: 'put',
    params: 'sigpharm/prescribe',
    postBody: { appt_id }
  });
};

export const getSignaturePharmacyCredentials = () => {
  return fetchData({ params: 'sigpharm' });
};

export const setSignaturePharmacyCredentials = ({
  clin_guid: user_guid,
  ...postBody
}: {
  clin_guid: string;
  prescriber_id: string;
  secure_pin: string;
}) => {
  return fetchData({
    method: 'post',
    params: 'clinicians/sigpharm',
    postBody: { user_guid, ...postBody }
  });
};

export const updateUrgency = (user_guid, case_id, urg_code) => {
  const options = {
    method: 'POST',
    params: 'cases/urgency',
    postBody: { user_guid, case_id, urg_code }
  };
  return fetchData(options);
};

export const getPatientList = (clinician_user_guid, postBody) => {
  const searchParams = new URLSearchParams({
    ...postBody
  });
  const requestOption = {
    method: 'GET',
    params: `referrers/patients/${clinician_user_guid}?${searchParams.toString()}`
  };
  return fetchData(requestOption);
};

export const getClientList = (params) => {
  const searchParams = new URLSearchParams({
    ...params
  });
  const requestOption = {
    method: 'GET',
    params: `admin/companies/APPUSER/?${searchParams.toString()}`
  };
  return fetchData(requestOption);
};

export const getCaseEligibility = (case_id: number) => {
  const requestOption = {
    method: 'GET',
    params: `cases/eligibility/APPUSER?case_id=${case_id}`
  };
  return fetchData(
    requestOption,

    z.object({
      case_eligibility: z.array(
        z.object({
          case_id: z.number(),
          eligibility: z.array(
            z.object({
              F2F_CONSULT_CHECK: z.union([z.literal('true'), z.literal('false')]),
              F2F_PHYSIO_CHECK: z.union([z.literal('true'), z.literal('false')]),
              VIRTUAL_ONLY: z.union([z.literal('true'), z.literal('false')])
            })
          ),
          policies: z.array(
            z.object({
              policy_code: z.string(),
              applied: z.union([z.literal('true'), z.literal('false')])
            })
          )
        })
      )
    })
  );
};

const SelectedLetterTokensSchema = z.object({
  appt_letter_tokens: z.array(
    z.object({
      token_code: z.string(),
      show_in_letter: z.number(),
      token_description: z.string(),
      blank_value_warning: z.number()
    })
  )
});

export type SelectedLetterTokens = z.infer<typeof SelectedLetterTokensSchema>;

export const getSelectedLetterTokens = (appointment_id: number) => {
  // TODO - reinstate calls when BE is ready
  // const requestOption = {
  //   method: 'GET',
  //   params: `/clinicians/appt/letter/tokens/APPUSER?appt_id=${appointment_id}`
  // };
  // return fetchData(requestOption, SelectedLetterTokensSchema);

  return (() => ({
    appt_letter_tokens: []
  })) as VLThunkAction<SelectedLetterTokens>;
};

type PostedLetterToken = {
  user_guid: string;
  appt_id: number;
  token_code: string;
  show_in_letter: boolean;
  blank_value_warning: boolean;
};

export const postSelectedLetterTokens = (token: PostedLetterToken) => {
  // TODO - reinstate calls when BE is ready
  // const requestOption = {
  //   method: 'POST',
  //   params: 'clinicians/appt/letter/tokens',
  //   postBody: token
  // };
  // return fetchData(requestOption);

  return (() => ({})) as VLThunkAction<{}>;
};
