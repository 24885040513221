import getConfig from 'next/config';

export function tryDeriveEndpointName() {
  // NextJs does not allow destructuring process.env!
  // eslint-disable-next-line prefer-destructuring
  const SETTINGS_URL = process.env.SETTINGS_URL;

  return (
    getConfig().publicRuntimeConfig.AWS_ENDPOINT_NAME ??
    SETTINGS_URL?.replace(/(http|https):\/\//g, '').replace(/api\.virtuallucyweb\.co\.uk\/.*/g, '')
  );
}

export const API_ENDPOINT = tryDeriveEndpointName();

export default API_ENDPOINT;
