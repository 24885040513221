import React, { useState, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Auth } from 'aws-amplify';
import { Button } from 'antd';
import LogRocket from 'logrocket';
import useMounted from '@hooks/useMounted';
import useTheme from '@hooks/useTheme';
import Themes from '@lib/context/themes';
import useRuntimeConfig, { getDynamicConfig } from '@vl-core/hooks/useConfig';
import Link from 'next/link';
import { useHAR } from 'vl-common/src/hooks/useHAR';
import { harServiceWorkerIsRunning, clearHARserviceWorker } from 'vl-common/src/lib/manageHARserviceWorker';
import TestingModal from '@components/TestingModal/TestingModal';
import DumpModal from '@components/TestingModal/DumpModal';
import AccessibilityContainer from '@components/Accessibility/AccessibilityContainer';
import useAuth from '@vl-core/useAuth';
import { useViewportSize } from 'vl-common/src/hooks/useWindowSize';
import { css } from '@emotion/react';
import useZoom from '@hooks/useZoom';
import UIVersionLabel from 'vl-common/src/components/UIVersionLabel';
import { NavigationFooter } from './NavigationFooter';

const Footer: React.FC<{ className?: string }> = ({ className }) => {
  const { user, logRocketInitialised } = useAuth();
  const [logRocketSessionURL, setLogRocketSessionURL] = useState('');
  const [devToolsIsVisible, setDevToolsIsVisible] = useState(true);
  const isMounted = useMounted();
  const { theme, selectTheme, overrideThemeDetection } = useTheme();
  const { LUCY_ENV, DATABASE_VERSION } = useRuntimeConfig();
  const isProd = LUCY_ENV.includes('prod');
  const { record, dump, dumpedHAR, clear } = useHAR(getDynamicConfig);
  const [testingModalOpen, setTestingModalOpen] = useState(false);
  const [harIsRunning, setHarIsRunning] = useState(false);
  const router = useRouter();
  const [inTestingMode, setInTestingMode] = useState(sessionStorage.getItem('mocked') === 'true');
  const [inRecordingMode, setInRecordingMode] = useState(sessionStorage.getItem('recording') === 'true');
  const [dumpModalOpen, setDumpModalOpen] = useState(false);
  const [highZoom, setHighZoom] = useState(false);
  const { width: actualWidth } = useViewportSize();
  const zoom = useZoom();
  const notZoomed = zoom < 115;

  /** Is the user a patient? */
  const isPatient = useMemo(() => {
    return user?.user_type_code === 'PATIENT';
  }, [user]);

  const isRegistering = router.asPath.includes('/register' || '/create-account');

  useEffect(() => {
    if (zoom > 300) {
      setHighZoom(true);
    } else {
      setHighZoom(false);
    }
  }, [zoom]);

  useEffect(() => {
    if (isMounted() && logRocketInitialised) {
      LogRocket.getSessionURL((url) => {
        if (isMounted()) {
          setLogRocketSessionURL(url);
        }
      });
    }
  }, [isMounted, logRocketInitialised]);

  useEffect(() => {
    harServiceWorkerIsRunning(setHarIsRunning);
  }, []);

  const enterTestingMode = (file: string, userType: string) => {
    setTestingModalOpen(false);
    if (file) {
      sessionStorage.setItem('har', `${userType}/${file}`);
      router.push(`/login/?har=har/${userType}/${file}`);
      setInTestingMode(true);
    }
  };

  const exitTestingMode = async () => {
    setTestingModalOpen(false);
    setInTestingMode(false);
    await sessionStorage.removeItem('har');
    await sessionStorage.removeItem('mocked');
    // @ts-ignore
    Auth.authenticatedUser = null;
    await router.push('/login');
  };

  const cycleThemes = () => {
    const themesArray = Object.keys(Themes);
    overrideThemeDetection();

    selectTheme(themesArray[(themesArray.indexOf(theme.id) + 1) % themesArray.length]);
  };
  if (isPatient && !isRegistering && actualWidth <= 768 && notZoomed) {
    return (
      <>
        <NavigationFooter
          cycleThemes={cycleThemes}
          theme={theme}
          logRocketSessionURL={logRocketSessionURL}
          isProd={isProd}
          DATABASE_VERSION={DATABASE_VERSION}
          inRecordingMode={inRecordingMode}
          setInRecordingMode={setInRecordingMode}
          inTestingMode={inTestingMode}
          setDumpModalOpen={setDumpModalOpen}
          setTestingModalOpen={setTestingModalOpen}
          clear={clear}
          record={record}
          dump={dump}
        />
        <TestingModal
          visible={testingModalOpen}
          onCancel={() => {
            setTestingModalOpen(false);
          }}
          enterTestingMode={enterTestingMode}
          exitTestingMode={exitTestingMode}
          inTestingMode={inTestingMode}
          setInTestingMode={setInTestingMode}
        />
        <DumpModal
          visible={dumpModalOpen}
          har={dumpedHAR || undefined}
          onCancel={() => {
            setDumpModalOpen(false);
          }}
        />
      </>
    );
  }

  return (
    <footer {...{ className }}>
      <div
        className="footer-container"
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 0.5em;
          align-items: center;
          justify-content: center;
          z-index: 1000;
          background-color: #fff;
          zoom: ${highZoom ? '50%' : '100%'};
          border-top: solid 1px var(--productIron);
          padding: 5px 1em;

          position: fixed;
          bottom: 0;
          width: 100%;
        `}
      >
        <div>
          <UIVersionLabel />
        </div>
        <img src="/static/images/hbsuk_logo.png" alt="HBS logo" width="40px" />
        <a
          rel="noreferrer"
          target="_blank"
          href="/patients/legal/?all_policies=0&activeTab=3"
          tabIndex={0}
          data-testid="terms"
        >
          Terms
        </a>
        <Link href="/accessibility" data-testid="accessibility">
          Accessibility
        </Link>
        <a href="/regulatory">
          <img src="/static/images/ukca.png" alt="UKCA Mark" width="25px" />
        </a>
        {logRocketSessionURL && (
          <a href={logRocketSessionURL}>
            <img src="/static/images/logRocket.png" width="30px" alt="LogRocket" />
          </a>
        )}
        {!isProd && devToolsIsVisible && (
          <>
            <div>
              Dev Tools:{' '}
              <Button data-testid="dev-change-theme" onClick={cycleThemes} size="small">
                Theme({theme.id})
              </Button>
            </div>

            <div data-testid="db-version">DB Version: {DATABASE_VERSION}</div>

            <Button data-testid="hide-dev-footer" onClick={() => setDevToolsIsVisible(false)} size="small">
              Hide
            </Button>
            <Button
              onClick={() => {
                clear();
              }}
              disabled={inRecordingMode || inTestingMode}
              size="small"
              data-testid="clear-button"
            >
              Clear
            </Button>
            <Button
              onClick={() => {
                if (!inRecordingMode) {
                  record().then(() => {
                    setInRecordingMode(true);
                    sessionStorage.setItem('recording', 'true');
                  });
                }
              }}
              size="small"
              data-testid="record-button"
              disabled={inTestingMode}
              style={inRecordingMode ? { background: 'green', color: 'white' } : {}}
            >
              {!inRecordingMode ? 'Record' : 'Recording...'}
            </Button>
            <Button
              onClick={() => {
                dump();
                setInRecordingMode(false);
                sessionStorage.removeItem('recording');
                setDumpModalOpen(true);
              }}
              size="small"
              data-testid="dump-button"
            >
              Dump
            </Button>
            <Button
              onClick={() => {
                setTestingModalOpen(true);
              }}
              size="small"
              data-testid="testing-mode-button"
              style={inTestingMode ? { background: 'green', color: 'white' } : {}}
              disabled={inRecordingMode}
            >
              Testing mode
            </Button>
          </>
        )}
        {!isProd && !devToolsIsVisible && (
          <Button
            onClick={() => setDevToolsIsVisible(true)}
            className="dev-tools-item"
            size="small"
            data-testid="show-dev-tools"
          >
            Show dev tools
          </Button>
        )}
        {harIsRunning && (
          <Button data-testid="remove-har-button" onClick={clearHARserviceWorker} size="small">
            Disable HAR
          </Button>
        )}

        <AccessibilityContainer />
      </div>
      <TestingModal
        visible={testingModalOpen}
        onCancel={() => {
          setTestingModalOpen(false);
        }}
        enterTestingMode={enterTestingMode}
        exitTestingMode={exitTestingMode}
        inTestingMode={inTestingMode}
        setInTestingMode={setInTestingMode}
      />
      <DumpModal
        visible={dumpModalOpen}
        har={dumpedHAR || undefined}
        onCancel={() => {
          setDumpModalOpen(false);
        }}
      />
    </footer>
  );
};

Footer.defaultProps = {
  className: undefined
};

export default Footer;
